import 'slick-carousel'
import momemt from 'moment';
import slick from 'slick-carousel';

jQuery(document).ready(function ($) {

  // mobile menu
  $('.menu-trigger').click(function () {
    $(this).toggleClass('active');
    $('.menu').toggleClass('active');
  })

  // sliders
  $('.slider-music').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></button>',
  });

  $('.slider-videos').slick({
    slidesToShow: 2,
    slidesToScroll: 1,
    mobileFirst: true,
    prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></button>',
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
        }
      }
    ]
  });

  // video trigger
  $('.video-item').click(function (e) {
    e.preventDefault()
    const target = $(this).attr('data-yt-id')
    $('#videos iframe').attr('src', `https://www.youtube.com/embed/${target}?autoplay=1`)
  })

  // events
  $.ajax({
    url: 'https://rest.bandsintown.com/artists/id_15510001/events?app_id=45PRESS_DAVID_J',
    method: 'GET',
    dataType: 'json',
    error: () => {
      alert('Error fetching events!');
    },
    success: data => {
      const events = $('#events');
      let html = '';
      if (data.length) {
        for (let event of data) {
          let region = event.venue.region ? event.venue.region : event.venue.country;
          let location = event.venue.city + ', ' + region;
          if (location === ', ') { location = ''; }
          if (event.venue.name.toLowerCase() === 'streaming live') { location = 'Online'; }
          html += '<div class="event">';
          html += '<div class="event-date">' + momemt(event.datetime).format('MMM D') + '</div>';
          html += '<div class="event-info">';
          html += '<div class="event-location">' + location + '</div>';
          html += '<div class="event-venue">' + event.venue.name + '</div>';

          if (event.lineup.length >= 2) {
            html += '<div class="event-lineup">With ';
            const featuredArtists = event.lineup.slice(1);

            html += featuredArtists.map((element, index) => {
              return index < featuredArtists.length - 1 ? element + ', ' : element;
            }).join('');

            html += '...</div>';
          }

          html += '</div>';
          html += '<div class="event-links">';
          for (let offer of event.offers) {
            html += '<a href="' + offer.url + '" target="_blank" class="btn btn-blue">' + offer.type + '</a>';
          }
          html += '</div>';
          html += '</div>';
        }
        events.html(html);
      } else {
        events.html('No upcoming events.');
      }
    }
  });

  // Newsletter
  $("#david-j-email-signup-website").on("submit", function (e) {
    // Prevent default browser actions on form submission.
    e.preventDefault();
    // Get all the form user submitted data and get it ready to be sent.
    var data = $(this).serialize();
    // POST all the data to the SMF submission endpoint URL.
    $.ajax({
      type: "POST",
      url: "https://subs.sonymusicfans.com/submit",
      dataType: "json",
      data: data,
      xhrFields: {
        withCredentials: false
      },
      success: function (data) {
        $("#david-j-email-signup-website, .newsletter-alert.error").hide();
        $(".newsletter-alert.success").show();
      },
      error: function (err) {
        $(".newsletter-alert.error").show();
      }
    });
  });

  setTimeout(() => {
    $('#merch iframe').attr('title', 'Merch');
  }, 1000)

});